<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="configuracoes-gerais-form" />
                    <h3 class="ml-1">Parâmetros Gerais</h3>
                    <TourComponent tourName="configuracoes-gerais" />
                </div>
                <TabView v-if="!loading" v-model:activeIndex="active">
                    <TabPanel header="Parâmetro de SLA">
                        <ConfiguracoesSLA />
                    </TabPanel>
                    <TabPanel header="Distribuição de agendamentos">
                        <DistribuicoesAgendamentos />
                    </TabPanel>
                    <TabPanel header="Configurações Agendamentos">
                        <ConfiguracoesAgendamentos />
                    </TabPanel>
                    <TabPanel v-if="hasPermissaoControleDeFaltaConsulta()" header="Controle de Falta Consulta">
                        <ControleDeFaltaConsulta />
                    </TabPanel>
                </TabView>
                <Toast />
            </div>
        </div>
    </div>
</template>

<script>
import ConfiguracoesSLA from './components/ConfiguracoesSLA.vue';
import ConfiguracoesAgendamentos from './components/ConfiguracoesAgendamentos.vue';
import DistribuicoesAgendamentos from './components/DistribuicoesAgendamentos.vue';
import ControleDeFaltaConsulta from './components/ControleDeFaltaConsulta.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';
import TourComponent from '../../components/TourComponent.vue';

export default {
    components: { ConfiguracoesAgendamentos, ConfiguracoesSLA, DistribuicoesAgendamentos, ControleDeFaltaConsulta, AppInfoManual, TourComponent },
    data() {
        return {
            active: 0,
            loading: false
        };
    },
    mounted() {},
    watch: {},
    methods: {
        hasPermissaoControleDeFaltaConsulta() {
            return this.$checkPermission('configuracoes_empresa:gerar_csv');
        }
    }
};
</script>
