import BaseService from "../../../services/BaseService";
import { getClientBase } from '@/services/http'

export default class ConfigDistAgendamentoService extends BaseService {
    constructor() {
        super('config-dist-agendamento');
        this.path = 'config-dist-agendamento';
    }

    findByCompany() {
        return getClientBase().get(this.path + '/by-company');
    }

    getProfissionais(id) {
        return getClientBase().get(this.path + '/profissionais/' + id);
    }

    adicionarProfissional(id, data) {
        return getClientBase().post(this.path + '/profissionais/' + id, data);
    }

    removerProfissional(id) {
        return getClientBase().delete(this.path + '/profissionais/' + id);
    }

    definirRedistribuirAoFinalizarDemanda(id, data) {
        return getClientBase().post(this.path + `/profissionais/${id}/redistribuir-ao-finalizar-demanda`, data);
    }

    getDemandasNaoRealizadas(id, params) {
        return getClientBase().get(this.path + '/nao-realizadas/' + id, { params });
    }

    distribuirAgora() {
        return getClientBase().post(this.path + '/distribuir');
    }
}