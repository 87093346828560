<template>
    <div>
        <div class="flex flex-row justify-content-between">
            <div class="text-800 mb-1">
                <span class="text-primary pi pi-info-circle" v-tooltip="'Configurações Agendamentos'"></span>
                Configurações para permitir cancelar, desistir ou alterar os agendamentos com status 'Agendado'
            </div>
        </div>
        <div class="text-900 font-normal text-lg mb-5">
            Selecione para quais
            <span class="font-medium">Procedimentos</span>
            será possível <strong>cancelar</strong>, <strong>desistir</strong> ou <strong>alterar</strong> o agendamento com status
            <span class="font-medium">'Agendado'</span>, e informe também quantas horas de antecedência.
        </div>
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
        <div :style="loading ? 'opacity: 30%;' : ''">
            <div class="body">
                <DataTable
                    v-if="!loading"
                    v-model:editingRows="editingRows"
                    :loading="loading"
                    :row-hover="true"
                    :value="records"
                    breakpoint="640px"
                    class="p-datatable-sm"
                    dataKey="id"
                    editMode="row"
                    responsiveLayout="stack"
                    table-style="width: 100%"
                >
                    <template #empty> Nenhum registro encontrado. </template>
                    <Column
                        field="procedimento"
                        header="Procedimento"
                        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 60%"
                    >
                        <template #editor="{ data }">
                            <MultiSelect
                                v-model="data.procedimentos"
                                :options="procedimentos"
                                :virtual-scroller-options="{ itemSize: 34, lazy: true }"
                                style="width: 100%"
                                dataKey="id"
                                display="chip"
                                filter
                                id="procedimentos"
                                option-label="name"
                                option-value="id"
                                placeholder="Selecione o exame..."
                            />
                        </template>
                        <template #body="slotProps">
                            {{ slotProps?.data?.procedimentos }}
                        </template>
                    </Column>
                    <Column field="horasAntesParaCancelar" header="Horas antes" style="width: 14%">
                        <template #editor="{ data, field }">
                            <InputNumber id="limiteDatas" inputStyle="width: 50px;" v-model="data[field]" mode="decimal" showButtons :step="1" />
                        </template>
                        <template #body="slotProps"> {{ slotProps?.data?.horasAntesParaCancelar }}h </template>
                    </Column>
                    <Column field="createdAt" header="Criado" style="width: 14%">
                        <template #body="slotProps">
                            {{ $filters.formatDate(slotProps.createdAt) }}
                        </template>
                    </Column>
                    <Column bodyClass="text-right" style="width: 6%">
                        <template #body="slotProps">
                            <Button
                                icon="pi pi-check"
                                v-if="editandoRecord[slotProps.data.id]"
                                class="p-button-text p-button-secondary"
                                @click="confirmarEdicaoRecord(slotProps)"
                            />
                            <Button
                                icon="pi pi-times"
                                v-if="editandoRecord[slotProps.data.id]"
                                class="p-button-text p-button-secondary"
                                @click="cancelarEdicaoRecord(slotProps)"
                            />
                        </template>
                    </Column>
                    <Column bodyClass="text-right" style="width: 6%">
                        <template #body="slotProps">
                            <Button
                                v-if="!editandoRecord[slotProps.data.id] && !editingRows.length > 0"
                                icon="pi pi-trash"
                                class="p-button-text p-button-secondary"
                                @click="confirmDeleteRecord(slotProps.data)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="w-2 md:mt-0">
                <Button
                    v-if="records.length <= 0"
                    label="Adicionar Item"
                    icon="pi pi-plus"
                    class="p-button p-button-outlined mr-2 mt-5"
                    @click="adicionarItem"
                />
            </div>
        </div>
    </div>
    <AppDeleteDialog
        v-if="showDeleteRecordDialog"
        v-model:visible="showDeleteRecordDialog"
        :record="record"
        @onConfirm="onClickDeleteRecord"
        @onClose="onClickCloseDeleteRecord"
    >
    </AppDeleteDialog>
</template>
<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            records: [],
            loading: false,
            showDeleteRecordDialog: false,
            editingRows: [],
            editandoRecord: {},
            service: null,
            serviceProcedimentos: null,
            procedimentos: []
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    mounted() {
        this.service = new BaseService('/configuracoes-agendamentos-cancelar');
        this.serviceProcedimentos = new BaseService('/procedures');
        this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                const { data } = await this.service.findAll({});
                this.records = data;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Ops, algo de errado aconteceu!', detail: error.message, life: 8000 });
            }
            this.loading = false;
        },
        async loadProcedimentos() {
            const { data } = await this.serviceProcedimentos.findAll({ limit: 9999 });
            this.procedimentos = data.items.map((procedimento) => ({
                name: procedimento.name,
                id: procedimento.id
            }));
        },
        async adicionarItem() {
            await this.loadProcedimentos();
            const record = {
                id: -Math.random() * 100
            };
            this.records.push(record);
            this.editingRows = [record];
            this.editandoRecord[record.id] = true;
        },
        confirmDeleteRecord(record) {
            this.record = record;
            this.openDeleteRecordDialog();
        },
        openDeleteRecordDialog() {
            this.showDeleteRecordDialog = true;
        },
        onClickCloseDeleteRecord() {
            this.showDeleteRecordDialog = false;
        },
        async onClickDeleteRecord() {
            try {
                await this.service.remove();
                this.load();
                this.showDeleteRecordDialog = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro excluído com sucesso!',
                    life: 3000
                });
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 3000
                });
            }
        },
        async confirmarEdicaoRecord(slotProps) {
            const { index, data } = slotProps;
            try {
                if (!data.horasAntesParaCancelar) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'O campo Horas Antes é obrigatório!',
                        life: 3000
                    });
                }

                if (data?.id <= 0) {
                    delete data.id;
                }

                const procedimentoIds = this.procedimentos.map((procedimento) => procedimento.id);
                const response = await this.service.save({
                    horasAntesParaCancelar: data.horasAntesParaCancelar || undefined,
                    procedimentosIds: data.procedimentos?.length ? data.procedimentos : procedimentoIds
                });
                data.id = response?.data?.id;
                this.records[index] = data;
                this.editingRows = [];
                this.editandoRecord[data.id] = false;
                this.$toast.add({ severity: 'success', summary: 'Salvo com sucesso!', life: 8000 });
            } catch (error) {
                if (!data.id) {
                    this.records.splice(index, 1);
                    this.editingRows = [];
                }
                this.$toast.add({ severity: 'error', summary: 'Ops, algo de errado aconteceu!', detail: error.message, life: 8000 });
            }
            this.load();
        },
        editarRecord(slotProps) {
            const { data } = slotProps;
            this.editingRows = [data];
            this.editandoRecord[data.id] = true;
        },
        cancelarEdicaoRecord(slotProps) {
            const { data, index } = slotProps;
            this.editingRows = [];
            this.editandoRecord[data.id] = false;

            if (data.id <= 0) {
                this.records.splice(index, 1);
            }
        }
    }
};
</script>
