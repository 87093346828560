<template lang="">
    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
    <div class="formgrid grid m-2">
        <div class="field col-2">
            <label for="quantidade">Quantidade a distribuir</label>
            <InputText
                id="quantidade"
                type="number"
                class="w-full"
                v-model="form.quantidade"
                required="true"
                autofocus
                autocomplete="off"
                :class="{ 'p-invalid': submitted && !form.quantidade }"
            />
            <small class="p-error" v-if="submitted && !form.quantidade">Este campo é obrigatório.</small>
        </div>
        <div class="field ml-2 col-2">
            <label for="parametro">Parametros</label>
            <Dropdown
                id="parametro"
                class="w-full"
                v-model="form.parametro"
                :options="parametros"
                optionValue="value"
                optionLabel="label"
                placeholder="Selecione..."
                :class="{ 'p-invalid': submitted && !form.parametro }"
            />
            <small class="p-error" v-if="submitted && !form.parametro">Este campo é obrigatório.</small>
        </div>
        <div class="field ml-2 col-2" v-show="isParametroCidade">
            <label for="cidade" class="mr-1">Cidade</label>
            <DropdownCidade id="cidade" class="w-full" v-model="cidade" />
        </div>
        <div class="field ml-2 col-2">
            <label for="ordem">Ordem</label>
            <Dropdown
                id="ordem"
                class="w-full"
                v-model="form.ordem"
                :options="ordens"
                optionValue="value"
                optionLabel="label"
                placeholder="Selecione..."
                :class="{ 'p-invalid': submitted && !form.ordem }"
            />
            <small class="p-error" v-if="submitted && !form.ordem">Este campo é obrigatório.</small>
        </div>
        <div class="field field-checkbox ml-2 mt-5 col-2">
            <label class="m-2 ml-0" for="distribuirAutomaticamente">Distribuir automaticamente?</label>
            <InputSwitch id="distribuirAutomaticamente" v-model="form.distribuirAutomaticamente" />
        </div>
        <div class="field mt-5 ml-2">
            <Button
                label="Salvar"
                :disabled="!formDirty"
                :loading="loadingSalvar"
                icon="pi pi-check"
                class="p-button-primary"
                @click="onClickSalvarDistribuicao"
            />
        </div>
        <div class="formgrid grid col-6">
            <div class="field">
                <Fieldset legend="Agendamentos em aberto"
                    ><h3 class="flex align-items-center justify-content-center">{{ quantAgendamentos }}</h3></Fieldset
                >
            </div>
            <div class="field">
                <Fieldset legend="Profissionais inseridos"
                    ><h3 class="flex align-items-center justify-content-center">{{ quantProfissionais }}</h3></Fieldset
                >
            </div>
        </div>
    </div>
    <div class="col-12">
        <h3>Profissionais para distribuir</h3>
        <ProgressBar v-if="loadingProfissionais" mode="indeterminate" style="height: 0.3em" />

        <div class="formgrid grid m-3 col-6">
            <div class="field">
                <label for="profissional" class="mr-1">Profissional</label>
                <DropdownProfissional id="profissional" v-model="profissional" :disabled="!form.id" />
            </div>
            <div class="field ml-2">
                <Button
                    label="Adicionar"
                    :loading="loadingAdicionar"
                    :disabled="!profissional"
                    icon="pi pi-check"
                    class="p-button-primary"
                    @click="onClickAdicionarProfissional"
                />
            </div>
        </div>

        <DataTable :value="profissionais" responsiveLayout="scroll">
            <Column field="profissional.id" header="Código"></Column>
            <Column field="profissional.nome" header="Nome"></Column>
            <Column bodyClass="text-right" header="Redistribuir ao finalizar" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <InputSwitch
                        id="redistribuir-ao-finalizar-demanda"
                        v-show="!loadingRedistribuir[slotProps.data.id]"
                        v-model="slotProps.data.redistribuirAoFinalizarDemanda"
                        @click="onClickRedistribuirDemandaAoFinalizar(slotProps.data)"
                    />
                    <Button
                        icon="pi pi-trash"
                        class="p-button-text p-button-info"
                        v-tooltip="'Loading'"
                        v-show="loadingRedistribuir[slotProps.data.id]"
                        :disabled="true"
                        :loading="true"
                    />
                </template>
            </Column>
            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <Button
                        icon="pi pi-trash"
                        class="p-button-text p-button-danger"
                        v-tooltip="'Excluir'"
                        @click="onClickConfirmDeleteRecord(slotProps.data)"
                    />
                </template>
            </Column>
        </DataTable>
        <div class="flex flex-column md:flex-row md:justify-content-end md:ml-auto mt-2">
            <Button
                label="Demandas não realizadas"
                class="p-button-link"
                :loading="loadingDemandasNaoRealizadas"
                :disabled="!profissionais.length"
                @click="onClickDemandasNaoRealizadas"
            />
            <Button
                label="Distribuir agora"
                :loading="loadingDistribuirAgora"
                :disabled="!profissionais.length"
                icon="pi pi-check"
                class="p-button-primary"
                @click="onClickDistribuirAgora"
            />
        </div>
    </div>

    <AppDeleteDialog
        v-if="showAppDeleteDialog"
        v-model:visible="showAppDeleteDialog"
        :loadingRemove="loadingRemove"
        @onConfirm="onClickDeleteRecord"
        @onClose="showAppDeleteDialog = false"
    >
    </AppDeleteDialog>

    <Dialog
        header="Demandas não realizadas"
        v-model:visible="showDemandasNaoRealizadasDialog"
        :breakpoints="{ '860px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
    >
        <DataTable :value="demandasNaoRealizadas" responsiveLayout="scroll">
            <Column field="user.id" header="Código"></Column>
            <Column field="user.name" header="Nome"></Column>
            <Column field="createdAt" header="Data">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="agendamentoId" header="Agendamento"></Column>
            <Column field="agendamento.funcionario.name" header="Funcionario"></Column>
        </DataTable>
        <Paginator
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>
        <template #footer>
            <Button label="Ok" icon="pi pi-check" @click="showDemandasNaoRealizadasDialog = false" autofocus />
        </template>
    </Dialog>
</template>
<script>
import AreaMedicaService from '@/services/AreaMedicaService';
import ConfigDistAgendamentoService from '../services/ConfigDistAgendamentoService';
import { showSuccess, showError } from '@/utils/Toast';
import DropdownProfissional from '../../profissionais/components/DropdownProfissional';
import DropdownCidade from '../../cidades/components/DropdownCidade';
import StatusAgendamento from '../../../enums/StatusAgendamento';
import { equals, clone } from 'ramda';

export default {
    components: { DropdownProfissional, DropdownCidade },
    data() {
        return {
            $service: null,
            submitted: false,
            loading: false,
            loadingSalvar: false,
            loadingRemove: false,
            loadingProfissionais: false,
            loadingAdicionar: false,
            loadingDistribuirAgora: false,
            loadingDemandasNaoRealizadas: false,
            loadingRedistribuir: {},
            showAppDeleteDialog: false,
            showDemandasNaoRealizadasDialog: false,
            record: {},
            profissional: null,
            cidade: null,
            quantAgendamentos: 0,
            quantProfissionais: 0,
            parametros: [
                {
                    value: 'SUGESTAO_DATA',
                    label: 'Sugestão de data'
                },
                {
                    value: 'CRIADO_SOLICITANTE',
                    label: 'Criado pelo solicitante'
                },
                {
                    value: 'CIDADE',
                    label: 'Cidade'
                }
            ],
            ordens: [
                {
                    value: 'ASC',
                    label: 'Crescente'
                },
                {
                    value: 'DESC',
                    label: 'Decrescente'
                }
            ],
            profissionais: [],
            demandasNaoRealizadas: [],
            form: {},
            initialForm: {},
            perPage: 10,
            total: 0,
            page: 1,
            currentPerPage: null
        };
    },
    computed: {
        formDirty() {
            return !equals(this.initialForm, this.form);
        },
        isParametroCidade() {
            return this.form.parametro == 'CIDADE';
        }
    },
    watch: {
        cidade() {
            this.form.cidadeId = this.cidade?.id;
        }
    },
    created() {
        this.$service = new ConfigDistAgendamentoService();
        this.$serviceAgendamento = new AreaMedicaService('/agendamentos');
    },
    mounted() {
        this.load();
    },
    methods: {
        async onClickSalvarDistribuicao() {
            try {
                this.loadingSalvar = true;
                this.submitted = true;
                const response = await this.$service.save(this.form);
                this.form.id = response.data.id;
                this.initialForm = clone(this.form);
                this.loadingSalvar = false;
                showSuccess(this.$toast, 'Alterado com sucesso');
            } catch (error) {
                this.loadingSalvar = false;
                showError(this.$toast, error);
            }
        },
        async onClickAdicionarProfissional() {
            try {
                this.loadingAdicionar = true;
                await this.$service.adicionarProfissional(this.form.id, {
                    profissionalId: this.profissional?.id
                });
                this.profissional = null;
                this.loadingAdicionar = false;
                this.loadProfissionais();
                showSuccess(this.$toast, 'Adicionado com sucesso');
            } catch (error) {
                this.loadingAdicionar = false;
                showError(this.$toast, error);
            }
        },
        async onClickDistribuirAgora() {
            try {
                this.loadingDistribuirAgora = true;
                await this.$service.distribuirAgora();
                this.loadingDistribuirAgora = false;
                showSuccess(this.$toast, 'Distribuido com sucesso');
            } catch (error) {
                this.loadingDistribuirAgora = false;
                showError(this.$toast, error);
            }
        },
        async load() {
            await this.loadConfig();
            await this.loadProfissionais();
            await this.loadAgendamentos();
        },
        async loadConfig() {
            try {
                this.loading = true;

                const response = await this.$service.findByCompany();
                this.form = response.data || {};
                this.initialForm = clone(this.form);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        async loadProfissionais() {
            try {
                if (!this.form.id) {
                    return;
                }
                this.loadingProfissionais = true;
                const response = await this.$service.getProfissionais(this.form.id);
                this.profissionais = response.data || [];
                this.quantProfissionais = this.profissionais.length;
                this.loadingProfissionais = false;
            } catch (error) {
                this.loadingProfissionais = false;
                showError(this.$toast, error);
            }
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.loadDemandasNaoRealizadas();
        },
        async loadDemandasNaoRealizadas() {
            try {
                if (!this.form.id) {
                    return;
                }
                this.loadingDemandasNaoRealizadas = true;
                const response = await this.$service.getDemandasNaoRealizadas(this.form.id, {
                    limit: this.currentPerPage,
                    page: this.page
                });
                this.demandasNaoRealizadas = response.data.items || [];
                this.total = response.data.meta?.totalItems;
                this.totalPage = response.data.meta?.totalPages;
                this.loadingDemandasNaoRealizadas = false;
            } catch (error) {
                this.loadingDemandasNaoRealizadas = false;
                showError(this.$toast, error);
            }
        },
        async loadAgendamentos() {
            const filtrosExtras = {
                status: [StatusAgendamento.EM_ABERTO]
            };
            const response = await this.$serviceAgendamento.findAll({
                limit: 1,
                page: 1,
                filter: '',
                filtrosExtras,
                sort: 'createdAt ASC'
            });
            this.quantAgendamentos = response.data.meta.totalItems;
        },
        onClickConfirmDeleteRecord(record) {
            this.record = record;
            this.showAppDeleteDialog = true;
        },
        async onClickRedistribuirDemandaAoFinalizar(record) {
            try {
                this.record = record;
                this.loadingRedistribuir[record.id] = true;

                await this.$service.definirRedistribuirAoFinalizarDemanda(this.record.id, {
                    redistribuirAoFinalizarDemanda: !record.redistribuirAoFinalizarDemanda
                });
                
                this.loadingRedistribuir[record.id] = false;
                showSuccess(this.$toast, 'Alterado com sucesso.');
            } catch (error) {
                this.loadProfissionais();
                this.loadingRedistribuir[record.id] = false;
                showError(this.$toast, error);
            }
        },
        async onClickDeleteRecord() {
            try {
                this.loadingRemove = true;

                await this.$service.removerProfissional(this.record.id);
                this.loadProfissionais();
                this.loadingRemove = false;
                this.showAppDeleteDialog = false;
            } catch (error) {
                this.loadingRemove = false;
                showError(this.$toast, error);
            }
        },
        async onClickDemandasNaoRealizadas() {
            await this.loadDemandasNaoRealizadas();
            this.showDemandasNaoRealizadasDialog = true;
        }
    }
};
</script>
